import React from 'react'
import Nav from './components/Nav'
import { Link } from 'react-router-dom'
import Header from './components/Header'
import Projects from './components/Projects'
import How from './components/How'
import About from './components/About'
import Faq from './components/Faq'
import Calltoaction from './components/Calltoaction'
import Footer from './components/Footer'

const Index = () => {
  return (
    <>
    <Nav activeLink={"Index"}/>
    <Header />
    <How />
    <About/>
    <Projects />
    <Faq />
    <Calltoaction />
    <Footer />
    </>
  )
}

export default Index
