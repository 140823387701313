import React from 'react'
import { Link } from 'react-router-dom'

const Calltoaction = () => {
  return (
        <section id="calltoaction">
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <span><strong>Obtenga <span className='text-green'>ingresos</span> + <span className='text-green'>ganancias de capital</span></strong></span>
              <h2>Su próxima inversión inmobiliaria está aquí</h2>
            </div>
            <div className='col-md-4'>
              <br />
            <a className='btn btn-white btn-lg' href="https://app.orioncapital.com.py"> Invertir Ahora</a>
            </div>
          </div>
        </div>
        </section>
  )
}

export default Calltoaction