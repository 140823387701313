import React from 'react'
import Nav from './components/Nav'
import { Link } from 'react-router-dom'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'


import Img1 from './images/img2-2.jpg'
import Img2 from './images/i1.jpg'
import Img3 from './images/i2.jpg'
import Img4 from './images/i3.jpg'
import Mark from './images/mark.svg'
import Footer from './components/Footer'
import Calltoaction from './components/Calltoaction'


const Proyecto = () => {
  return (
    <>
    <Nav activeLink={"Index"}/>
    <br /><br /><br /><br />
    <div className='container proyecto'>
    <div className='row'>
        <div className='col-md-12'>
          <h5>Proyecto de Apartamentos Jara Sky Towers</h5>
        </div>
    </div>
      <div className='row'>
        <div className='col-md-8'>
          <div className='product-images'>
          <img id="main-image" className='img-fluid main' src={Img1} alt='1' />
          <div>
          <img onClick={() => {document.getElementById("main-image").src = Img1}} className="thumbnail img-fluid" src={Img1} alt='1' />
          <img onClick={() => {document.getElementById("main-image").src = Img2}} className="thumbnail img-fluid" src={Img2} alt='1' />
          <img onClick={() => {document.getElementById("main-image").src = Img3}} className="thumbnail img-fluid" src={Img3} alt='1' />
          <img onClick={() => {document.getElementById("main-image").src = Img4}} className="thumbnail img-fluid" src={Img4} alt='1' />
          </div>
          </div>
          
        </div>
        <div className='col-md-4'>
        <div class="divcard-dark">

 <div class="cardinfo">
  <div class="div-2">
   
    <div className='div-5-1'>
      <strong>Tipo de desarrollo:</strong> Tipo1<br />
      <strong>Monto mínimo de entrada:</strong> 20.000 U$D<br />
      <hr />
      TIR:<strong>50%</strong> VAN:<strong>50.000 U$D</strong> <br />
      ROC:<strong>50%</strong> ROE:<strong>50%</strong> ROS:<strong>50%</strong>
    </div>
    <div class="div-6">
      <div class="div-7">
        <span>
          Fondeo Actual 
        </span>
        <span></span>
        <span>
          (48%)
        </span>
      </div>
      <div class="div-8">Venta</div>
    </div>
    <div class="div-9-2">
      <div className='div-9-fill-green' style={{  width: "48%" }}></div>
    </div>
    {/*<div class="div-10">
      <div class="div-11">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a946c680-3f62-47f3-9a58-7f9499b55409?apiKey=859fcd62e1a949e59d3a0240a7b39fbc&"
          class="img-3"
        />
        <div class="div-12">
          <span>
            70&nbsp;
          </span>
          <span></span>
          <span>
            Inversores
          </span>
        </div>
      </div>
      <div class="div-13">45 Días</div>
    </div>*/}
  </div>
    <br />
    {/*<a className='btn btn-danger btn-lg' href='#'>Más Info <i class="fas fa-plus"></i> </a> &nbsp;&nbsp;*/}
    <a className='btn btn-primary btn-lg' href='https://app.orioncapital.com.py'>Invertir Ahora</a>
  
  </div>
</div>

<div className='tiempos'>

<h4>Tiempos / Hitos</h4>
<ul class="timeline">
<li>
  <span><strong>1 Ago 2023</strong></span>
  <span class="float-right">Inicio de etapa de Fondeo</span>

</li>
<li>
  <span><strong>30 Dic 2023</strong></span>
  <span class="float-right">Fin de etapa de Fondeo</span>
</li>
<li>
  <span><strong>15 Mar 2024</strong></span>
  <span class="float-right">Inicio de Obras</span>

</li>
<li>
  <span><strong>Marzo 2025</strong></span>
  <span class="float-right">Finalización de Obras</span>
</li>
</ul>
</div>

        </div>














      </div>





    <div className='row'>
        <div className='col-md-8'>
          <div className='product-info'>
          <h5>Proyecto de Apartamentos Jara Sky Towers</h5>
          <p> <img src={Mark} className="img-2" /> Av. Pitiantuta, 77 - Bo. Jara, Asunción</p>
          <hr />
          <p>Un apartamento que redefine el concepto de estilo de vida urbano. Ubicado en el corazón de la ciudad, este inmueble ofrece una combinación única de comodidades modernas, diseño elegante y una ubicación privilegiada. </p>
          </div>
        </div>
        <div className='col-md-4'>
          
        </div>
    </div>





    <div className='row'>
        <div className='col-md-8'>
          <div className='product-info'>
          <h5>Por qué invertir en un apartamento en Jara Sky Towers</h5>
          <hr />
<p>&nbsp;&nbsp;<i class="fas fa-check-circle"></i>&nbsp; Se ubica en una zona atractiva, en el corazón de la ciudad. </p>
<p>&nbsp;&nbsp;<i class="fas fa-check-circle"></i>&nbsp; Ingresos de alquiler anuales fijos y atractivos. </p>
<p>&nbsp;&nbsp;<i class="fas fa-check-circle"></i>&nbsp; El interés fijo está indexado a la inflación. </p>
<p>&nbsp;&nbsp;<i class="fas fa-check-circle"></i>&nbsp; Ganancias de capital variables. </p>
<p>&nbsp;&nbsp;<i class="fas fa-check-circle"></i>&nbsp; Los locales fueron tasados por un tasador independiente en 300.000 U$D. </p>
<p>&nbsp;&nbsp;<i class="fas fa-check-circle"></i>&nbsp; El propietario del proyecto es un experimentado administrador de bienes raíces. </p>






          </div>
        </div>
        <div className='col-md-4'>
          
        </div>
    </div>


    </div>
    <Calltoaction />
    <Footer />
    </>
  )
}

export default Proyecto
