import React from 'react'
import { Link } from 'react-router-dom'
import Wave from '../images/wave-white.svg'
import Moc1 from '../images/moc1.webp'

const About = () => {
  return (
        <section id="About" className='bg-gray'>
        <div className='container'>

          <div className='row'>
            <div className='col-md-6 aboutp'>
              <img className='img-fluid' src={Moc1} alt='1' />
            </div>
            <div className='col-md-6 aboutp'>
            <h2>Acerca de Let Me Invest</h2>
            <p>

La misión de Let Me Invest es forjar un ecosistema financiero que promueva la inclusión. Creemos firmemente que la libertad financiera desempeña un papel crucial en el camino hacia la prosperidad. En nuestra era, se requieren herramientas más eficientes para construir el patrimonio. Por esta razón, hemos trabajado en nuestra plataforma, la manera más sencilla y veloz de invertir en bienes raíces. Hemos desarrollado este enfoque mediante el uso de tecnología y un proceso en línea diseñado para brindar a cada inversionista la tranquilidad de saber que su dinero se invierte de forma segura, sin complicaciones y con la meta de proteger su capital.
            </p>
            </div>
          </div>
        </div>
        <img src={Wave} alt="W" className='wave1' />
        </section>
  )
}

export default About