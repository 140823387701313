import React from 'react'
import { Link } from 'react-router-dom'
import Bubble from '../images/bubble.svg'
import WaveGray from '../images/wave-gray.svg'
import Img1 from '../images/img1-2.jpg'
import Img2 from '../images/img2-2.jpg'
import Img3 from '../images/img3-2.jpg'
import Mark from '../images/mark.svg'

const Projects = () => {
  return (
        <section id="Proyectos">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <span className='titulo1'>
              <img src={Bubble} alt="b" />
              Proyectos
              </span>
              <h2>Proyectos Destacados</h2>
              <br />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4 l-proyectos'>
  <Link to='/proyecto'>
  <div class="divcard">
	<div>
    <img src={Img2} alt="1" className='img'/>
	</div>
 <div class="cardinfo">
  <div class="div-2">
    <div class="div-3">Proyecto Jara Sky Towers</div>
    <div class="div-4">
    <img src={Mark} className="img-2" />
      <div class="div-5">Av. Pitiantuta, 77 - Bo. Jara, Asunción</div>
    </div>
    <hr />
    <div className='div-5-1'>
      <strong>Monto mínimo de entrada:</strong> 20.000 U$D
    </div>
    <div class="rentabilidad">
    <span>28%</span>
    <div>Rentabilidad Anual Estimada</div>
    </div>
    <div class="div-6">

    </div>
    <div class="div-9">
      <div className='div-9-fill' style={{  width: "77%" }}></div>
    </div>
    <div class="div-10">
      <span>Fondeo Actual (77%)</span>
    </div>
  </div>
     <a className='btn btn-primary btn-lg' href='#'>Ver Detalles</a>
  
  </div>
</div>
</Link>
</div>
            






<div className='col-md-4 l-proyectos'>
  <Link to='/proyecto'>
  <div class="divcard">
	<div>
  <img src={Img1} alt="1" className='img'/>
	</div>
 <div class="cardinfo">
  <div class="div-2">
    <div class="div-3">Proyecto Trinidad Green Plaza</div>
    <div class="div-4">
    <img src={Mark} className="img-2" />
      <div class="div-5">Av. Stma. Trinidad 11 - Bo. Trinidad, Asunción</div>
    </div>
    <hr />
    <div className='div-5-1'>
      <strong>Monto mínimo de entrada:</strong> 20.000 U$D
    </div>
    <div class="rentabilidad">
    <span>25%</span>
    <div>Rentabilidad Anual Estimada</div>
    </div>
    <div class="div-6">

    </div>
    <div class="div-9">
      <div className='div-9-fill' style={{  width: "48%" }}></div>
    </div>
    <div class="div-10">
      <span>Fondeo Actual (48%)</span>
    </div>
  </div>
    <a className='btn btn-primary btn-lg' href='#'>Ver Detalles</a>
  
  </div>
</div>
</Link>
</div>
            









<div className='col-md-4 l-proyectos'>
  <Link to='/proyecto'>
  <div class="divcard">
	<div>
  <img src={Img3} alt="1" className='img'/>
	</div>
 <div class="cardinfo">
  <div class="div-2">
    <div class="div-3">Proyecto Molas Luxury Park</div>
    <div class="div-4">
    <img src={Mark} className="img-2" />
      <div class="div-5">Av. Molas Lopez, 265 - Bo. Trinidad, Asunción</div>
    </div>
    <hr />
    <div className='div-5-1'>
      <strong>Monto mínimo de entrada:</strong> 20.000 U$D
    </div>
    <div class="rentabilidad">
    <span>24%</span>
    <div>Rentabilidad Anual Estimada</div>
    </div>
    <div class="div-6">

    </div>
    <div class="div-9">
      <div className='div-9-fill' style={{  width: "27%" }}></div>
    </div>
    <div class="div-10">
      <span>Fondeo Actual (27%)</span>
    </div>
  </div>
    <a className='btn btn-primary btn-lg' href='#'>Ver Detalles</a>
  
  </div>
</div>
</Link>
</div>
            








          </div>
        </div>
        <img src={WaveGray} alt="W" className='wave1' />
      </section>

  )
}

export default Projects
