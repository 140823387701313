import React from "react"
import { Link } from 'react-router-dom'
import Logo from "../images/logo-letme-c-blue.svg"

const Nav = ({ activeLink }) => {
  return (
    <header id="Header">
  <nav class="navbar navbar-expand-md navbar-light fixed-top bg-light">
    <div class="container">
      <img className="logo-w img-fluid" src={Logo} alt="Logo" />
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="container navbar-collapse collapse" id="navbarCollapse">
        <ul class="navbar-nav me-auto mb-2 mb-md-0">
          <li class="nav-item">
           <Link className="nav-link" aria-current="page" to="/#Header">Inicio</Link>
          </li>
          <li class="nav-item">
           <a class="nav-link" href="/#How">Como Funciona</a>
          </li>
          <li class="nav-item">
           <a class="nav-link" href="/#About">Nosotros</a>
          </li>
          <li class="nav-item">
           <a class="nav-link" href="/#Proyectos">Proyectos</a>
          </li>
          <li class="nav-item">
           <a class="nav-link" href="/#Faq">Preguntas Frecuentes</a>
          </li>
        </ul>
        
        <a className='btn btn-secondary btn-lg' href="https://app.orioncapital.com.py"> Invertir Ahora</a>
      </div>
      
      
     
      
    </div>
  </nav>
</header>
  )
}

export default Nav
