import React from 'react'
import { Link } from 'react-router-dom'
import Bubble from '../images/bubble.svg'
import WaveGray from '../images/wave-gray.svg'
import Img1 from '../images/01.png'
import Img2 from '../images/02.png'
import Img3 from '../images/03.png'

const How = () => {
  return (
        <section id="How">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <span className='titulo1'>
              <img src={Bubble} alt="b" />
              El Proceso
              </span>
              <h2>Cómo Funciona</h2>
              <br />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <div className='how how1'>
                <img alt='1' src={Img1} />
                <h2>Crea tu Cuenta</h2>
                <p>Haz click en 
                <a href="http://app.orioncapital.com.py"> Quiero invertir </a> 
                 y sigue los pasos para registrarte en la plataforma. Luego inicia sesión y verás tu panel de control, donde puedes ver comprar y vender. Puedes invertir y ganar dinero.
                </p>
              </div>            
            </div>


            <div className='col-md-4'>
              <div className='how how2'>
                <img alt='1' src={Img2} />
                <h2>Elige una Propiedad</h2>
                <p>
                Analiza y elige la propiedad que mejor se adapte a tu presupuesto y plan de inversión. Puede ser cualquier cosa, desde una preventa hasta una propiedad en el mercado secundario.

                </p>
              </div>            
            </div>


            <div className='col-md-4'>
              <div className='how how3'>
                <img alt='1' src={Img3} />
                <h2>Invierte y Gana</h2>
                <p>
                Invierte en los proyectos que mejor se adapten a ti y hazlos parte de tu cartera de inversiones. Recuerda que en cualquier momento puedes ponerlos a la venta en el mercado secundario.

                </p>
              </div>            
            </div>
          </div>
        </div>
        <img src={WaveGray} alt="W" className='wave1' />
        </section>
  )
}

export default How