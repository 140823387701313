import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes} from "react-router-dom"
import './App.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Index from './pages/Index'
import Proyecto from './pages/Proyecto'

function App() {

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <div>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/proyecto" element={<Proyecto />}></Route>
        </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App
