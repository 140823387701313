import React from 'react'
import { Link } from 'react-router-dom'
import Bubble from '../images/bubble.svg'
import Wave from '../images/wave-white.svg'
import Imgfaq from '../images/faq.webp'

const Faq = () => {
  return (
        <section className='bg-gray' id="Faq">
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 text-center'>
              <span className='titulo1'>
              <img src={Bubble} alt="b" />
              FAQs
              </span>
              <h2>Preguntas más Frecuentes</h2>
              <br />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <img src={Imgfaq} alt='f' className='img-fluid' />
            </div>
            <div className='col-md-6'>
            <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      <i class="fas fa-question-circle"></i>&nbsp; ¿Cuándo se distribuyen los ingresos?
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Los ingresos de nuestras inversiones inmobiliarias se distribuyen de manera mensual. Esto significa que cada mes recibirás tus ganancias proporcionales a tu participación en la inversión. Nos esforzamos por brindar transparencia y consistencia en la distribución de ingresos para garantizar una experiencia de inversión sólida y predecible.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      <i class="fas fa-question-circle"></i>&nbsp; ¿Cuánto retorno de la inversión puedo esperar?
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      El retorno de la inversión puede variar según el proyecto y las condiciones del mercado. Sin embargo, nuestros proyectos están diseñados para ofrecer atractivas tasas de retorno. En promedio, nuestros inversores han experimentado un retorno anual competitivo. Antes de decidir invertir, proporcionamos proyecciones detalladas basadas en análisis de mercado y rendimiento histórico para que puedas tomar decisiones informadas.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      <i class="fas fa-question-circle"></i>&nbsp; ¿Cómo puedo recibir mis ingresos?
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      Ofrecemos diversas opciones para recibir tus ingresos de inversión. La forma más común es a través de depósito directo en tu cuenta bancaria. También brindamos opciones de transferencia electrónica para mayor comodidad. Nuestro objetivo es hacer que el proceso sea lo más sencillo posible para ti. Al unirte a nosotros, recibirás información detallada sobre cómo seleccionar la opción que mejor se adapte a tus necesidades y preferencias financieras.
      </div>
    </div>
  </div>
</div>









            </div>
          </div>
        </div>
        {/*  <img src={Wave} alt="W" className='wave1' />  */}
        </section>
  )
}

export default Faq