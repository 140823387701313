import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/logo-letme-c-blue.svg'

const Footer = () => {
  return (
    <>
    <section id="footer">
    <div className='container'>
      <div className='row'>
        <div className='col-md-4'>
          <img src={Logo} alt='logo' className="logo-w img-fluid"/>
          <p>Nuestro equipo explora y visita cientos de proyectos para seleccionar los mejores. Encontramos inquilinos, recopilamos alquileres, nos ocupamos de la gestión de propiedades y de las renovaciones si es necesario.</p>
          <div className='social'>
          <span><i class="fab fa-facebook-square"></i> </span><span><i class="fab fa-instagram-square"></i> </span><span><i class="fab fa-linkedin"></i> </span>
          </div>
          
        </div>
        <div className='col-md-4'>
          <h4>Enlaces</h4>
          <br />
          <ul class="navbar-nav ">
          <li class="nav-item">
           <a class="nav-link" href="/#How">Como Funciona</a>
          </li>
          <li class="nav-item">
           <a class="nav-link" href="/#About">Nosotros</a>
          </li>
          <li class="nav-item">
           <a class="nav-link" href="/#Proyectos">Proyectos</a>
          </li>
          <li class="nav-item">
           <a class="nav-link" href="/#Faq">Preguntas Frecuentes</a>
          </li>
        </ul>
        </div>
        <div className='col-md-4'>
        <h4>Contacto</h4>
        <br />
        <ul class="navbar-nav contact">
          <li>
           <a class="nav-link" href="#"><i class="fas fa-envelope"></i> hola@orioncapital.com.py</a>
          </li>
          <li>
           <a class="nav-link" href="#"><i class="fas fa-phone-square-alt"></i> +595900000000</a>
          </li>
          <li>
           <a class="nav-link" href="#"><i class="fas fa-building"></i> Direción Calle Numero</a>
          </li>
        </ul>
        </div>
      </div>
      
    </div>
    </section>
    <section>
    <div className='container'>
    <div className='row f2'>
        <div className='col-md-6'>
        <span>© 2024 Let Me Invest</span>
        <br />
        </div>
        <div className='col-md-6'>
        <span className='allrights'>Todos los Derechos Reservados</span>
          <br />
        </div>
      </div>
    </div>
    </section>
    </>
  )
}

export default Footer