import React from 'react'
import { Link } from 'react-router-dom'
import Wave1 from '../images/wave-white.svg'
import Moc1 from '../images/moc1.webp'


const Header = () => {
  return (
    <section class="pt-5 bg-gr position-relative bg5 cover"> 
        
        <div class="bg-overlay"></div>
        <br /><br />
        <div class="container pt-5 position-relative">
			
          <div class="row d-flex justify-content-between pt-lg-1 align-items-center">
            <div class="col-md-7 titulo">
            
             <h1>Invierte en Bienes Raíces de forma sencilla, desde donde estés.</h1>
              <h4>Inversiones inmobiliarias inteligentes. ¡Regístrate ahora y accede a las mejores oportunidades!</h4>
              <br />
              <a href="#Proyectos" className='btn btn-danger btn-lg'><i class="far fa-building"></i> Ver Proyectos</a> <a className='btn btn-primary btn-lg' href="https://app.orioncapital.com.py"><i class="far fa-user-circle"></i> Crear Cuenta</a>
              <br /><br />
            </div>
            <div class="col-md-5">
             <img className="img-fluid" src={Moc1} alt='moc1'/>
              
            </div>

          
          
          </div>
        </div>
        <img src={Wave1} alt="W" className='wave1' />
      </section>
      )
  }

export default Header